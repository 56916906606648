import { useState } from "react";
import { Link } from "react-router-dom";

export const Button = ({
  onMouseEnter,
  onMouseLeave,
  title,
  to,
  style,
  noHover,
}: {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  title: string;
  to: string;
  style?: React.CSSProperties;
  noHover?: boolean;
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Link
      to={to}
      style={{
        cursor: "pointer",
        color: "white",
        textDecoration: "none",
        width: "fit-content",
        transform: `${hover ? "scale(1.1)" : ""}`,
        transition: "transform 0.05s ease-in",
        ...style,
      }}
      onMouseEnter={() => {
        onMouseEnter?.();
        setHover(true && !noHover);
      }}
      onMouseLeave={() => {
        onMouseLeave?.();
        setHover(false);
      }}
    >
      {title}
    </Link>
  );
};
