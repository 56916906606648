import { useEffect, useRef } from "react";
import { Point } from "../../core";

export const Background = ({
  hexify,
  circle,
  fear,
}: {
  hexify: boolean;
  circle: boolean;
  fear: boolean;
}) => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = ref.current;
    const context = canvas?.getContext("2d");
    let animationFrameId: number;

    const render = () => {
      if (!context) {
        return;
      }

      draw(context, { hexify, circle, fear });
      animationFrameId = window.requestAnimationFrame(render);
    };

    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [hexify, circle, fear]);

  return (
    <canvas
      ref={ref}
      style={{
        position: "absolute",
        zIndex: "0",
      }}
    />
  );
};

const avgArea = Math.sqrt(window.innerWidth * window.innerHeight);
const avgSize = Math.sqrt(window.innerWidth ** 2 + window.innerHeight ** 2);

const N = Math.floor(avgSize * 0.9);
const MAX_D = Math.floor(Math.sqrt(avgArea) * 1.7);
const OPACITY = 0.2;
const SPEED = 0.5;

let P = new Array(N)
  .fill(0)
  .map(() => Point.random(window.innerWidth, window.innerHeight));
let V = new Array(N)
  .fill(0)
  .map(() => Point.random(2, 2))
  .map((p) => p.sub(Point.one));

const drawLine = (ctx: CanvasRenderingContext2D) => (p: Point, q: Point) => {
  const d = p.distance(q);
  if (d > MAX_D) return;

  ctx.strokeStyle = `rgba(255, 255, 255, ${Math.max(0, OPACITY * (1 - d / MAX_D))})`;

  ctx.beginPath();
  ctx.moveTo(p.x, p.y);
  ctx.lineTo(q.x, q.y);
  ctx.stroke();
};

const findMins = (S: Point[], T: Point[]) => {
  const gridS: { [key: string]: Point[] } = {};
  const gridT: { [key: string]: Point[] } = {};

  S.forEach((p) => {
    gridS[p.div(MAX_D).floor.hash] = [
      ...(gridS[p.div(MAX_D).floor.hash] || []),
      p,
    ];
  });

  T.forEach((p) => {
    gridT[p.div(MAX_D).floor.hash] = [
      ...(gridT[p.div(MAX_D).floor.hash] || []),
      p,
    ];
  });

  const closestMap: { [key: string]: Point } = {};

  Object.keys(gridS).forEach((key, ii) => {
    const Gs = gridS[key];
    const Gt = gridT[key];

    if (!Gs || !Gt) return;

    const p = Point.unhash(key);

    const Nt = [
      ...(gridT[p.add(new Point(-1, -1)).hash] || []),
      ...(gridT[p.add(new Point(0, -1)).hash] || []),
      ...(gridT[p.add(new Point(1, -1)).hash] || []),
      ...(gridT[p.add(new Point(-1, 0)).hash] || []),
      ...(gridT[p.add(new Point(1, 0)).hash] || []),
      ...(gridT[p.add(new Point(-1, 1)).hash] || []),
      ...(gridT[p.add(new Point(0, 1)).hash] || []),
      ...(gridT[p.add(new Point(1, 1)).hash] || []),
    ];

    Gs.map((s) => {
      return Gt.concat(Nt)
        .map((t) => {
          return [s, t, s.distance(t)];
        })
        .reduce((acc, [s, t, d]) => {
          if (d < (acc[2] || Number.POSITIVE_INFINITY)) return [s, t, d];
          return acc;
        }, []) as [Point, Point, number];
    }).forEach(([s, t]) => {
      closestMap[s.hash] = t;
    });
  });

  return closestMap;
};

const findMinsn2 = (S: Point[], T: Point[]) => {
  const closestMap: { [key: string]: Point } = {};

  S.forEach((s) => {
    let min: Point = new Point(
      Number.POSITIVE_INFINITY,
      Number.POSITIVE_INFINITY,
    );

    T.forEach((t) => {
      if (s.distance(t) < s.distance(min)) min = t;
    });

    closestMap[s.hash] = min;
  });

  return closestMap;
};

const hexD = MAX_D;

const hexGrid = (offset: Point) => {
  return new Array(110)
    .fill(0)
    .reduce(
      (acc, _, i) => [
        ...acc,
        new Point(
          (acc[acc.length - 1]?.x || 0) + (i % 2 ? hexD : hexD / 2),
          acc[acc.length - 1]?.y,
        ),
      ],
      [offset] as Point[],
    );
};

const T: Point[] = new Array(100)
  .fill(0)
  .map((_, i) => {
    return hexGrid(
      new Point(
        -30 + (i % 2 ? hexD : hexD / 4),
        -30 + (i * Math.sqrt(3 / 4) * hexD) / 2,
      ),
    );
  })
  .flat();

const CIRCLE = new Array(100)
  .fill(0)
  .map((_, i) =>
    new Point(window.innerWidth, window.innerHeight)
      .div(2)
      .add(
        new Point(
          300 * Math.cos((2 * Math.PI * i) / 100),
          300 * Math.sin((2 * Math.PI * i) / 100),
        ),
      ),
  );

let hasFoundClosest = false;
// let closestSet: Point[] = [];
let Tclosest: Point[] = [];

const draw = (
  ctx: CanvasRenderingContext2D,
  { hexify, circle, fear }: { hexify: boolean; circle: boolean; fear: boolean },
) => {
  const w = window.innerWidth;
  const h = window.innerHeight;

  ctx.canvas.width = w;
  ctx.canvas.height = h;

  if (fear && !hasFoundClosest) {
    const closestMap = findMinsn2(P, [new Point(w, h).div(2)]);

    Tclosest = P.map((p) => closestMap[p.hash]);
  }

  if (hexify && !hasFoundClosest) {
    const closestMap = findMins(P, T);

    Tclosest = P.map((p) => closestMap[p.hash]);
  }

  if (circle && !hasFoundClosest) {
    const closestMap = findMinsn2(P, CIRCLE);

    Tclosest = P.map((p) => closestMap[p.hash]);
  }

  if (hexify || circle || fear) {
    V = P.map((p, i) => {
      if (fear) {
        const t = Tclosest[i];
        const w: Point = p.sub(t);
        return V[i].add(w).mult(0.01);
      }

      if (circle) {
        const t = Tclosest[i];
        const w: Point = p.sub(t);
        return V[i].sub(w.div(V[i].distance(w))).mult(0.999);
      }

      const t = Tclosest[i];
      const w: Point = p.sub(t);
      return V[i].sub(w.div(Math.max(1, V[i].distance(w)))).mult(0.95);
    });

    hasFoundClosest = true;
  } else {
    hasFoundClosest = false;
  }

  P = P.map((p, i) => {
    const newPoint = V[i].mult(SPEED).add(p);

    if (newPoint.x < 0 || newPoint.x > w) {
      V[i].x *= -1;
      return p;
    }
    if (newPoint.y < 0 || newPoint.y > h) {
      V[i].y *= -1;
      return p;
    }

    return newPoint;
  });

  const grid: { [key: string]: Point[] } = {};

  P.forEach((p) => {
    grid[p.div(MAX_D).floor.hash] = [
      ...(grid[p.div(MAX_D).floor.hash] || []),
      p,
    ];
  });

  Object.keys(grid).forEach((key) => {
    const G = grid[key];

    const p = Point.unhash(key);

    const N = [
      ...(grid[p.add(new Point(-1, -1)).hash] || []),
      ...(grid[p.add(new Point(0, -1)).hash] || []),
      ...(grid[p.add(new Point(1, -1)).hash] || []),
      ...(grid[p.add(new Point(-1, 0)).hash] || []),
      ...(grid[p.add(new Point(1, 0)).hash] || []),
      ...(grid[p.add(new Point(-1, 1)).hash] || []),
      ...(grid[p.add(new Point(0, 1)).hash] || []),
      ...(grid[p.add(new Point(1, 1)).hash] || []),
    ];

    for (let i = 0; i < Math.min(10, G.length); i++) {
      for (let j = 0; j < i; j++) {
        drawLine(ctx)(G[i], G[j]);
      }
    }

    for (let i = 0; i < Math.min(10, G.length); i++) {
      for (let j = 0; j < N.length; j++) {
        drawLine(ctx)(G[i], N[j]);
      }
    }

    grid[key] = [];
  });
};
