import { useEffect, useRef } from "react";
import { Point, range } from "../../../core";

export const BlogBackground = () => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = ref.current;
    const context = canvas?.getContext("2d");
    let animationFrameId: number;

    let frame = 0;

    const render = () => {
      if (!context) {
        return;
      }

      draw(context, frame++);
      animationFrameId = window.requestAnimationFrame(render);
    };

    render();

    return () => {
      boids = range(number_of_boids).map(() => {
        return new Boid(
          Point.random(w, h),
          Point.random(2, 2).sub(new Point(1, 1)),
          new Point(0, 0),
        );
      });

      window.cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={ref}
      style={{
        position: "fixed",
        zIndex: "0",
      }}
    />
  );
};

const w = window.innerWidth;
const h = window.innerHeight;

class Boid {
  maxSpeed: number = 2;
  maxForce: number = 0.1;

  constructor(
    public position: Point,
    public velocity: Point,
    public acceleration: Point,
  ) {}

  update() {
    return new Boid(
      this.position.add(this.velocity).mod(w, h),
      this.velocity.add(this.acceleration).limit(this.maxSpeed),
      this.acceleration.limit(this.maxForce),
    );
  }

  steer(force: Point, max: number) {
    return new Boid(
      this.position,
      this.velocity,
      this.acceleration.add(force.limit(max)),
    );
  }
}

const drawBoid = (
  ctx: CanvasRenderingContext2D,
  position: Point,
  pointing: Point,
) => {
  ctx.fillStyle = "#dddddd51";
  ctx.strokeStyle = "#aaaaaa";
  ctx.lineCap = "round";
  ctx.lineWidth = 2;

  ctx.beginPath();

  const p1 = position.add(pointing);
  ctx.moveTo(p1.x, p1.y);

  const p2 = pointing.rotate(Math.PI - 0.7).add(position);
  ctx.lineTo(p2.x, p2.y);

  const p3 = position.sub(pointing.mult(0.5));
  ctx.lineTo(p3.x, p3.y);

  const p4 = pointing.rotate(-Math.PI + 0.7).add(position);
  ctx.lineTo(p4.x, p4.y);

  ctx.lineTo(p1.x, p1.y);

  ctx.shadowColor = "#080808";
  ctx.shadowBlur = 3;
  ctx.shadowOffsetY = 3;
  ctx.fill();
  ctx.stroke();
};

const avgArea = Math.sqrt(window.innerWidth * window.innerHeight);
const avgSize = Math.sqrt(window.innerWidth ** 2 + window.innerHeight ** 2);

const number_of_boids = Math.floor(
  (window.innerWidth * window.innerHeight) / 80 ** 2,
);

let boids = range(number_of_boids).map(() => {
  return new Boid(
    Point.random(w, h),
    Point.random(2, 2).sub(new Point(1, 1)),
    new Point(0, 0),
  );
});

const neighbors = (current: Boid, boids: Boid[]) => {
  return boids.filter((boid) => {
    const d = current.position.distance(boid.position);
    return d ? d < 30 : false;
  });
};

const separation = (boids: Boid[]) => {
  return boids.map((current) => {
    const n = neighbors(current, boids);
    return n
      .reduce(
        (acc, boid) => {
          const force = current.position.sub(boid.position);

          return acc.add(force.div(force.abs ** 2));
        },
        new Point(0, 0),
      )
      .div(n.length || 1);
  });
};

const alignment = (boids: Boid[]) => {
  return boids.map((current) => {
    const n = neighbors(current, boids);
    return n
      .reduce(
        (acc, boid) => {
          return acc.add(boid.velocity);
        },
        new Point(0, 0),
      )
      .div(n.length || 1);
  });
};

const cohesion = (boids: Boid[]) => {
  return boids.map((current) => {
    const n = neighbors(current, boids);

    const midPoint = n
      .reduce(
        (acc, boid) => {
          return acc.add(boid.position);
        },
        new Point(0, 0),
      )
      .div(n.length || 1);

    if (midPoint.x === 0 && midPoint.y === 0) {
      return current.position.sub(current.position);
    }

    return midPoint.sub(current.position);
  });
};

const avoidBorders = (boids: Boid[]) => {
  return boids.map((current) => {
    return new Point(
      Math.min(Math.abs(current.position.x), Math.max(current.position.x - w)),
      Math.min(Math.abs(current.position.y), Math.max(current.position.y - h)),
    );
  });
};

const draw = (ctx: CanvasRenderingContext2D, frame: number) => {
  const w = window.innerWidth;
  const h = window.innerHeight;

  ctx.canvas.width = w;
  ctx.canvas.height = h;

  ctx.strokeStyle = "#FFFFFF";

  boids.forEach((boid) => {
    drawBoid(ctx, boid.position, boid.velocity.normalize.mult(7));
  });

  let newBoids = [...boids];

  separation(boids).forEach((steer, i) => {
    newBoids[i] = newBoids[i].steer(steer, 0.08);
  });
  alignment(boids).forEach((steer, i) => {
    newBoids[i] = newBoids[i].steer(steer, 0.03);
  });
  cohesion(boids).forEach((steer, i) => {
    newBoids[i] = newBoids[i].steer(steer, 0.025);
  });

  boids = newBoids.map((boid) => boid.update());
};
