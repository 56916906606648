import GuitarPractice from "../../assets/guitar-practice-cropped.png";
import Minesweeper from "../../assets/minesweeper.png";
import Trax from "../../assets/trax.png";

import { HomeBar } from "../../components/home-bar";
import { PortfolioItem } from "./partials";
import { PortfolioBackground } from "./partials/background";

export const Portfolio = () => {
  return (
    <div>
      <PortfolioBackground />

      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          background: "#0d0c1d",
          zIndex: -1,
        }}
      />

      <div
        style={{
          width: "100%",
          height: "100%",
          background: "#0d0c1d",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          flexDirection: "column",
          gap: "30px",
          marginBottom: "100px",
        }}
      >
        <HomeBar selected="portfolio" />

        {(
          [
            {
              title: "GUITAR PRACTICE",
              description:
                "A simple tool to learn the frets and different scales.",
              image: GuitarPractice,
            },
            {
              title: "MINESWEEPER",
              description: "A low level web approach to minesweeper.",
              image: Minesweeper,
            },
            {
              title: "TRAX",
              description: "A quick note taking app that is ludacris fast.",
              image: Trax,
            },
          ] as const
        ).map((item) => {
          return <PortfolioItem {...item} />;
        })}
      </div>
    </div>
  );
};
