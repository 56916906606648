import { HomeBar } from "../../components/home-bar";

import { BlogBackground } from "./partials";

export const Blog = () => {
  return (
    <div>
      <BlogBackground />

      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "#0d0c1d",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <HomeBar selected="blog" />
      </div>
    </div>
  );
};
