import { Button } from ".";

type Options = "blog" | "portfolio" | "contact";
const allOptions = ["blog", "portfolio", "contact"];

export const HomeBar = ({ selected }: { selected: Options }) => {
  const isMobile = window.innerWidth < 500;

  return (
    <div
      style={{
        backdropFilter: "blur(10px)",
        boxShadow: "0px 0px 10px #0000006A",
        padding: "10px 20px",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "20px",
        marginTop: "40px",
      }}
    >
      <Button
        to="/"
        title="HOME"
        style={{
          letterSpacing: "1px",
          fontSize: isMobile ? "12px" : "16px",
        }}
      />

      {allOptions.map((option) => {
        return (
          <Button
            to={`/${option}`}
            title={option.toUpperCase()}
            noHover={selected === option}
            style={{
              letterSpacing: "1px",
              fontSize:
                selected === option
                  ? isMobile
                    ? "16px"
                    : "24px"
                  : isMobile
                    ? "12px"
                    : "16px",
              fontWeight: selected === option ? 800 : 400,
            }}
          />
        );
      })}
    </div>
  );
};
