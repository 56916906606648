export const range = (n: number) => {
  return new Array(n).fill(0).map((_, i) => i);
};

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export class Point {
  constructor(
    public x: number,
    public y: number,
  ) {}

  static get one() {
    return new Point(1, 1);
  }

  static get x() {
    return new Point(1, 0);
  }

  static get y() {
    return new Point(0, 1);
  }

  get hash() {
    return `${this.x}/${this.y}`;
  }

  static unhash(hash: string) {
    const [x, y] = hash.split("/").map((ns) => parseInt(ns));
    return new Point(x, y);
  }

  get floor() {
    return new Point(Math.floor(this.x), Math.floor(this.y));
  }

  add(p: Point) {
    return new Point(this.x + p.x, this.y + p.y);
  }

  equal(p: Point) {
    return p.x === this.x && p.y === this.y;
  }

  sub(p: Point) {
    return new Point(this.x - p.x, this.y - p.y);
  }

  mult(n: number) {
    return new Point(this.x * n, this.y * n);
  }

  div(n: number) {
    return new Point(this.x / n, this.y / n);
  }

  get abs() {
    return Math.sqrt(this.x ** 2 + this.y ** 2);
  }

  limit(n: number) {
    let d = this.abs;
    if (!d) return this;
    return this.mult(Math.min(d, n) / d);
  }

  distance(p: Point) {
    return this.sub(p).abs;
  }

  mod(x: number, y: number) {
    return new Point(mod(this.x, x), mod(this.y, y));
  }

  rotate(angle: number) {
    return new Point(
      this.x * Math.cos(angle) - this.y * Math.sin(angle),
      this.x * Math.sin(angle) + this.y * Math.cos(angle),
    );
  }

  get normalize() {
    return this.div(this.abs);
  }

  static random(xRange: number, yRange: number) {
    return new Point(Math.random() * xRange, Math.random() * yRange);
  }
}
