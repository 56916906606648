import { useEffect, useState } from "react";

export const useTimer = () => {
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFrame((f) => f + 1);
    }, 64);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return frame;
};
