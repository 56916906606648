import { HomeBar } from "../../components/home-bar";
import { ContactBackground } from "./partials";

export const Contact = () => {
  return (
    <div>
      <ContactBackground />

      <div
        style={{
          width: "100%",
          height: "100vh",
          background: "#0d0c1d",
          position: "fixed",
          zIndex: -1,
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <HomeBar selected="contact" />
      </div>
    </div>
  );
};
