import { memo, useEffect, useState } from "react";
import { Point, range, useTimer } from "../../../core";

export const ContactBackground = () => {
  const frame = useTimer() * 4;

  const w = window.innerWidth;
  const h = window.innerHeight;

  const isMobile = w < 500;

  const center = new Point(0, isMobile ? -220 : 0);

  return (
    <svg
      style={{
        position: "absolute",
        zIndex: "0",
        width: "100%",
        height: isMobile ? "calc(100% + 300px)" : "100%",
      }}
    >
      <g
        style={{
          transform: `translate(${w / 2}px, ${h / 2}px) scale(${(Math.max(w, h) / 1920) * (isMobile ? 1.3 : 1)})`,
        }}
      >
        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${130}`}
          fill="#FFFFFF"
          filter="blur(100px)"
        />

        <OuterFilled1 origin={center} angle={frame / 6 + 90} />

        <OuterFilled2 origin={center} angle={-frame / 6 + 90} />

        <AnnulusSection
          origin={center}
          width={390}
          height={0}
          radius={142}
          angle={-frame / 6 - 110}
        />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${180}`}
          stroke="black"
          strokeWidth="6"
          fill="transparent"
        />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${150}`}
          stroke="black"
          strokeWidth="4"
          fill="transparent"
        />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${95}`}
          stroke="black"
          strokeWidth="30"
          fill="transparent"
        />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${95}`}
          stroke="white"
          strokeWidth="2"
          fill="transparent"
          style={{
            filter: "blur(5px)",
          }}
        />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${90}`}
          stroke="black"
          strokeWidth="18"
          fill="transparent"
        />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${40}`}
          fill="#FFFFFF"
          filter="blur(15px)"
        />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${73}`}
          stroke="#555"
          strokeWidth="15"
          fill="transparent"
          filter="blur(5px)"
        />

        <rect
          x={`${center.x}`}
          y={`${center.y - 5}`}
          width={`${120}`}
          height={`${10}`}
          fill="white"
          filter="blur(4px)"
        />

        <rect
          x={`${center.x - 120}`}
          y={`${center.y + 7}`}
          width={`${100}`}
          height={`${13}`}
          fill="white"
          filter="blur(7px)"
        />

        <InnerFilled2 origin={center} angle={frame / 20} />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={`${23}`}
          fill="#eee"
          filter="blur(2px)"
        />

        <circle
          cx={`${center.x}`}
          cy={`${center.y}`}
          r={10}
          fill="white"
          filter="blur(0px)"
          style={{
            transform: `scale(${Math.sin(frame / 100) * 0.3 + 1})`,
            transition: "transform 0.064s linear",
            transformOrigin: `${center.x}px ${center.y}px`,
          }}
        />

        <Gear
          origin={center}
          angle={frame / 11}
          gearAngle={0.4}
          outerRadius={81}
          middleRadius={67}
          innerRadius={50}
          gears={12}
          stroke="#FFFFFF88"
          fill="#FFF1"
        />

        <OuterLines
          angle={frame / 12}
          origin={center}
          radius={65}
          gap={4}
          height={12}
          amount={40}
          width={2}
        />

        <AnnulusSection
          origin={center}
          width={230}
          height={1}
          radius={80}
          angle={frame / 3 - 37}
        />

        <OuterLines
          angle={frame / 12}
          origin={center}
          radius={91}
          gap={10}
          height={5}
          amount={36}
          width={2}
        />

        <InnerFilled1 origin={center} angle={frame / 9} />

        <Gear
          origin={center}
          outerRadius={140}
          middleRadius={130}
          innerRadius={125}
          angle={-frame / 8}
          gears={45}
        />

        <Outer1 origin={center} angle={frame / 8 + 90} />

        <Outer2 origin={center} angle={-frame / 10 - 120} />

        <Outer3 origin={center} angle={-frame / 5 - 120} />

        <Outer4 origin={center} angle={frame / 6 - 120} />

        <AnnulusSection
          origin={center}
          width={60}
          height={4}
          radius={195}
          angle={frame / 3 - 37}
        />

        <AnnulusSection
          origin={center}
          width={60}
          height={10}
          radius={195}
          angle={-frame / 3 - 37}
        />

        <AnnulusSection
          origin={center}
          width={50}
          height={25}
          radius={120}
          angle={frame / 6 - 110}
        />

        <AnnulusSection
          origin={center}
          width={90}
          height={10}
          radius={130}
          angle={-frame / 6 - 110}
        />

        <OuterLines
          amount={15}
          height={30}
          origin={center}
          angle={-frame / 7 + 100}
          radius={200}
          gap={5}
        />

        <OuterLines
          amount={6}
          height={30}
          origin={center}
          angle={-frame / 7 - 140}
          radius={200}
          gap={5}
        />

        <OuterLines
          amount={2}
          height={30}
          origin={center}
          angle={-frame / 7 - 170}
          radius={200}
          gap={15}
        />

        <OuterLines
          amount={2}
          height={30}
          origin={center}
          angle={-frame / 7 - 105}
          radius={200}
          gap={6}
        />

        <OuterLines
          amount={25}
          height={15}
          origin={center}
          angle={frame / 9 + 100}
          radius={265}
          gap={5}
        />

        <OuterLines
          amount={4}
          height={15}
          origin={center}
          angle={frame / 9}
          radius={265}
          gap={5}
        />

        <OuterLines
          amount={2}
          height={15}
          origin={center}
          angle={frame / 9 - 42}
          radius={265}
          gap={5}
        />

        {!isMobile && (
          <Tooltip
            left
            origin={center.add(new Point(-95, -95))}
            destination={center.add(new Point(-300, -300))}
            text="ABOUT ME"
            length={95}
          />
        )}

        <Text
          origin={center.add(
            isMobile ? new Point(-240, 350) : new Point(-580, -270),
          )}
          frame={frame}
          texts={[
            "I’m a fullstack developer who loves",
            "to solve complex problems and enjoys",
            "working with numbers and data. My",
            "passion for math has helped me develop",
            "a logical and analytical mindset",
            "that I apply to my work every day.",
            "math has taught me to be detail-oriented",
            "and precise, which helps me write",
            "clean and efficient code.",
            "",
            "I’m always looking for ways to improve",
            "my skills and stay up-to-date with the",
            "latest technologies. I enjoy working",
            "on projects that challenge me and",
            "push me to learn new things.",
            "",
            "I like working on projects that require",
            "both technical and analytical skills.",
            "My skills in both front-end and back-end",
            "development make me a valuable asset to",
            "any team.",
          ]}
        />

        {!isMobile && (
          <Tooltip
            origin={center.add(new Point(65, -65))}
            destination={center.add(new Point(300, 0))}
            text="CONTACTS & LINKS"
            length={192}
          />
        )}

        <text
          fill="white"
          cursor="pointer"
          x={center.x + (isMobile ? -190 : 300)}
          y={center.y + (isMobile ? 1050 : 30)}
          fontSize={isMobile ? 28 : 14}
          onClick={() => {
            window.location.replace("https://www.github.com/carlobagnoli");
          }}
        >
          github.com/carlobagnoli
        </text>

        <text
          fill="white"
          cursor="pointer"
          x={center.x + (isMobile ? -240 : 300)}
          y={center.y + (isMobile ? 1100 : 55)}
          fontSize={isMobile ? 28 : 14}
          onClick={() => {
            window.location.replace(
              "https://www.linkedin.com/in/carlo-bagnoli/",
            );
          }}
        >
          linkedin.com/in/carlo-bagnoli/
        </text>

        <text
          fill="white"
          cursor="copy"
          x={center.x + (isMobile ? -234 : 300)}
          y={center.y + (isMobile ? 1150 : 80)}
          fontSize={isMobile ? 28 : 14}
          onClick={() => {
            navigator.clipboard.writeText("carlo.bagnoli.gomez@gmail.com");
          }}
        >
          carlo.bagnoli.gomez@gmail.com
        </text>
      </g>
    </svg>
  );
};

interface Props {
  angle: number;
  origin: Point;
}

const OuterLines = ({
  angle,
  ...props
}: Props & {
  radius: number;
  height: number;
  width?: number;
  amount: number;
  gap: number;
}) => {
  return (
    <g
      style={{
        transformOrigin: `${props.origin.x}px ${props.origin.y}px`,
        transform: `rotate(${angle}deg)`,
        transition: "transform 0.1s linear",
      }}
    >
      <OuterLinesNoRotation {...props} />
    </g>
  );
};

const OuterLinesNoRotation = memo(
  function ({
    origin,
    radius,
    height,
    width = 5,
    amount,
    gap,
  }: {
    origin: Point;
    radius: number;
    height: number;
    width?: number;
    amount: number;
    gap: number;
  }) {
    return (
      <>
        {range(amount).map((i) => {
          const A = new Point(radius, 0)
            .rotate((i * gap * 2 * Math.PI) / 360)
            .add(origin);
          const B = new Point(radius + height, 0)
            .rotate((i * gap * 2 * Math.PI) / 360)
            .add(origin);

          return (
            <line
              key={i}
              x1={`${A.x}`}
              y1={`${A.y}`}
              x2={`${B.x}`}
              y2={`${B.y}`}
              stroke="white"
              strokeWidth={`${width}`}
            />
          );
        })}
      </>
    );
  },
  (oldProps, newProps) => {
    return oldProps.origin.equal(newProps.origin);
  },
);

const Outer1 = ({ angle, origin }: Props) => {
  return (
    <>
      <AnnulusSection
        origin={origin}
        width={170}
        height={60}
        radius={250}
        angle={angle - 25}
      />

      <AnnulusSection
        origin={origin}
        width={20}
        height={60}
        radius={250}
        angle={angle}
      />

      <AnnulusSection
        origin={origin}
        width={50}
        height={60}
        radius={250}
        angle={angle + 11}
      />
    </>
  );
};

const Outer2 = ({ angle, origin }: Props) => {
  return (
    <>
      <AnnulusSection
        origin={origin}
        width={220}
        height={100}
        radius={230}
        angle={angle - 37}
      />

      <AnnulusSection
        origin={origin}
        width={25}
        height={100}
        radius={230}
        angle={angle}
      />

      <AnnulusSection
        origin={origin}
        width={60}
        height={100}
        radius={230}
        angle={angle + 17}
      />
    </>
  );
};

const Outer3 = ({ angle, origin }: Props) => {
  return (
    <>
      <AnnulusSection
        origin={origin}
        width={180}
        height={7}
        radius={210}
        angle={angle - 37}
      />

      <AnnulusSection
        origin={origin}
        width={30}
        height={7}
        radius={225}
        angle={angle - 37}
      />
    </>
  );
};

const Outer4 = ({ angle, origin }: Props) => {
  return (
    <>
      <AnnulusSection
        origin={origin}
        width={230}
        height={15}
        radius={165}
        angle={angle - 37}
      />

      <AnnulusSection
        origin={origin}
        width={15}
        height={15}
        radius={165}
        angle={angle - 95}
      />

      <AnnulusSection
        origin={origin}
        width={260}
        height={27}
        radius={159}
        angle={angle - 161}
      />

      <AnnulusSection
        origin={origin}
        width={30}
        height={30}
        radius={124}
        angle={angle - 201}
      />

      <AnnulusSection
        origin={origin}
        width={40}
        height={15}
        radius={132}
        angle={angle - 181}
      />

      <AnnulusSection
        origin={origin}
        width={30}
        height={15}
        radius={190}
        angle={angle - 37}
      />
    </>
  );
};

const OuterFilled1 = ({ angle, origin }: Props) => {
  return (
    <>
      <AnnulusSection
        fill="#CCCCCC"
        stroke="transparent"
        origin={origin}
        width={300}
        height={40}
        radius={180}
        angle={angle}
      />

      <AnnulusSection
        fill="#CCCCCC"
        stroke="transparent"
        origin={origin}
        width={300}
        height={40}
        radius={180}
        angle={angle + 180}
      />

      <AnnulusSection
        fill="#CCCCCC"
        stroke="transparent"
        origin={origin}
        width={90}
        height={40}
        radius={180}
        angle={angle + 72}
      />

      <AnnulusSection
        fill="#CCCCCC"
        stroke="transparent"
        origin={origin}
        width={90}
        height={40}
        radius={180}
        angle={angle + 109}
      />

      <AnnulusSection
        fill="#CCCCCC"
        stroke="transparent"
        origin={origin}
        width={90}
        height={40}
        radius={180}
        angle={angle - 72}
      />

      <AnnulusSection
        fill="#CCCCCC"
        stroke="transparent"
        origin={origin}
        width={90}
        height={40}
        radius={180}
        angle={angle - 109}
      />
    </>
  );
};

const OuterFilled2 = ({ angle, origin }: Props) => {
  return (
    <>
      <AnnulusSection
        fill="#BBBBBB"
        stroke="transparent"
        origin={origin}
        width={550}
        height={5}
        radius={210}
        angle={angle}
      />

      <AnnulusSection
        fill="#BBBBBB"
        stroke="transparent"
        origin={origin}
        width={320}
        height={5}
        radius={210}
        angle={angle + 125}
      />

      <AnnulusSection
        fill="#BBBBBB"
        stroke="transparent"
        origin={origin}
        width={120}
        height={5}
        radius={210}
        angle={angle + 190}
      />

      <AnnulusSection
        fill="#BBBBBB"
        stroke="transparent"
        origin={origin}
        width={150}
        height={5}
        radius={210}
        angle={angle + 259}
      />

      <AnnulusSection
        fill="#BBBBBB"
        stroke="transparent"
        origin={origin}
        width={85}
        height={5}
        radius={210}
        angle={angle + 222}
      />
    </>
  );
};

const InnerFilled1 = ({ angle, origin }: Props) => {
  return (
    <>
      <AnnulusSection
        noGlow
        fill="#FFFFFF33"
        stroke="transparent"
        origin={origin}
        width={190}
        height={30}
        radius={115}
        angle={angle}
      />

      <AnnulusSection
        noGlow
        fill="#FFFFFF33"
        stroke="transparent"
        origin={origin}
        width={190}
        height={30}
        radius={115}
        angle={angle + 180}
      />

      <AnnulusSection
        noGlow
        fill="#FFFFFF33"
        stroke="transparent"
        origin={origin}
        width={50}
        height={30}
        radius={115}
        angle={angle + 72}
      />

      <AnnulusSection
        noGlow
        fill="#FFFFFF33"
        stroke="transparent"
        origin={origin}
        width={50}
        height={30}
        radius={115}
        angle={angle + 109}
      />

      <AnnulusSection
        noGlow
        fill="#FFFFFF33"
        stroke="transparent"
        origin={origin}
        width={50}
        height={30}
        radius={115}
        angle={angle - 72}
      />

      <AnnulusSection
        noGlow
        fill="#FFFFFF33"
        stroke="transparent"
        origin={origin}
        width={50}
        height={30}
        radius={115}
        angle={angle - 109}
      />
    </>
  );
};

const InnerFilled2 = ({ angle, origin }: Props) => {
  return (
    <>
      <AnnulusSection
        noGlow
        fill="#FFFFFF"
        stroke="transparent"
        origin={origin}
        width={278}
        height={10}
        radius={30}
        angle={angle}
      />

      <AnnulusSection
        noGlow
        fill="#FFFFFF"
        stroke="transparent"
        origin={origin}
        width={278}
        height={10}
        radius={30}
        angle={angle + 160}
      />

      <AnnulusSection
        noGlow
        fill="white"
        stroke="transparent"
        origin={origin}
        width={5}
        height={10}
        radius={30}
        angle={angle + 80}
      />
    </>
  );
};

const AnnulusSectionNoRotation = memo(
  function ({
    origin,
    width,
    height,
    radius,
    fill = "transparent",
    stroke = "white",
    noGlow,
  }: {
    origin: Point;
    radius: number;
    width: number;
    height: number;
    fill?: string;
    stroke?: string;
    noGlow?: boolean;
  }) {
    const innerRadius = radius - height / 2;
    const outerRadius = radius + height / 2;
    const innerAngle = width / (2 * radius);

    const A = new Point(
      origin.x + Math.sin(innerAngle) * innerRadius,
      origin.y + Math.cos(innerAngle) * innerRadius,
    );
    const B = new Point(
      origin.x + Math.sin(innerAngle) * outerRadius,
      origin.y + Math.cos(innerAngle) * outerRadius,
    );
    const C = new Point(
      origin.x - Math.sin(innerAngle) * outerRadius,
      origin.y + Math.cos(innerAngle) * outerRadius,
    );
    const D = new Point(
      origin.x - Math.sin(innerAngle) * innerRadius,
      origin.y + Math.cos(innerAngle) * innerRadius,
    );

    return (
      <path
        d={`
        M ${A.x} ${A.y}
        L ${B.x} ${B.y}
        A ${outerRadius} ${outerRadius} 0 0 1 ${C.x} ${C.y}
        L ${D.x} ${D.y}
        A ${innerRadius} ${innerRadius} 0 0 0 ${A.x} ${A.y}
      `}
        stroke={stroke}
        fill={fill}
        strokeWidth="1"
        style={{
          filter: noGlow ? "blur(3px)" : "drop-shadow(0px 0px 6px white)",
        }}
      />
    );
  },
  (oldProps, newProps) => {
    return oldProps.origin.equal(newProps.origin);
  },
);

const AnnulusSection = ({
  angle,
  ...props
}: {
  origin: Point;
  radius: number;
  width: number;
  height: number;
  angle: number;
  fill?: string;
  stroke?: string;
  noGlow?: boolean;
}) => {
  return (
    <g
      style={{
        transformOrigin: `${props.origin.x}px ${props.origin.y}px`,
        transform: `rotate(${angle}deg)`,
        transition: "transform 0.1s linear",
      }}
    >
      <AnnulusSectionNoRotation {...props} />
    </g>
  );
};

const Gear = ({
  angle,
  ...props
}: {
  origin: Point;
  gears: number;
  outerRadius: number;
  innerRadius: number;
  middleRadius: number;
  angle: number;
  gearAngle?: number;
  fill?: string;
  stroke?: string;
}) => {
  return (
    <g
      style={{
        transformOrigin: `${props.origin.x}px ${props.origin.y}px`,
        transform: `rotate(${angle}deg)`,
        transition: "transform 0.1s linear",
      }}
    >
      <GearNoRotation {...props} />
    </g>
  );
};

const GearNoRotation = memo(
  function ({
    origin,
    innerRadius,
    outerRadius,
    gears,
    middleRadius,
    gearAngle = 0.25,
    fill = "black",
    stroke = "transparent",
  }: {
    origin: Point;
    gears: number;
    outerRadius: number;
    innerRadius: number;
    middleRadius: number;
    gearAngle?: number;
    fill?: string;
    stroke?: string;
  }) {
    const S = new Point(middleRadius, 0).add(origin);
    const Si = new Point(innerRadius, 0).add(origin);

    return (
      <path
        d={`
        M ${S.x} ${S.y}
        ${range(gears)
          .map((i) => {
            const offset = (n: number) => (Math.PI * n) / gears;

            const A = new Point(outerRadius - middleRadius, 0)
              .rotate(gearAngle)
              .add(new Point(middleRadius, 0))
              .rotate(offset(i * 2))
              .add(origin);
            const B = new Point(outerRadius - middleRadius, 0)
              .rotate(-gearAngle)
              .add(new Point(middleRadius, 0))
              .rotate(offset(i * 2 + 1))
              .add(origin);
            const C = new Point(middleRadius, 0)
              .rotate(offset(i * 2 + 1))
              .add(origin);
            const D = new Point(middleRadius, 0)
              .rotate(offset(i * 2 + 2))
              .add(origin);

            return `
              L ${A.x} ${A.y}
              L ${B.x} ${B.y}
              L ${C.x} ${C.y}
              L ${D.x} ${D.y}
            `;
          })
          .join(" ")}
        M ${Si.x} ${Si.y}
        A ${innerRadius} ${innerRadius} 0 0 0 ${Si.sub(origin).rotate(Math.PI).add(origin).x} ${Si.sub(origin).rotate(Math.PI).add(origin).y}
        A ${innerRadius} ${innerRadius} 0 0 0 ${Si.x} ${Si.y}
        Z
      `}
        fill={fill}
        stroke={stroke}
      />
    );
  },
  (oldProps, newProps) => {
    return oldProps.origin.equal(newProps.origin);
  },
);

const Tooltip = ({
  origin,
  destination,
  length,
  text,
  left,
  onMouseEnter,
  onMouseLeave,
}: {
  origin: Point;
  destination: Point;
  length: number;
  text: string;
  left?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  return (
    <>
      <path
        d={`
          M ${origin.x} ${origin.y}
          L ${destination.x} ${destination.y}
          L ${destination.x + (left ? -length : length)} ${destination.y}
        `}
        fill="transparent"
        stroke="white"
      />

      <text
        x={`${destination.x - (left ? length : 0)}`}
        y={`${destination.y - 10}`}
        fontSize="20px"
        fill="white"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cursor="context-menu"
      >
        {text}
      </text>
    </>
  );
};

const Text = ({
  origin,
  texts,
  frame,
}: {
  origin: Point;
  texts: string[];
  frame: number;
}) => {
  const [random, setRandom] = useState([[""]]);
  const [currIndex, setCurrIndex] = useState(0);

  const isMobile = window.innerWidth < 500;

  const [_, p] = texts.reduce(
    ([len, p], text, i) => {
      return [
        len + text.length,
        new Point(
          currIndex - len < 0 ? p.x : currIndex - len,
          currIndex - len < 0 ? p.y : i,
        ),
      ];
    },
    [0, new Point(0, 0)] as [number, Point],
  );

  useEffect(() => {
    const dict = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    setCurrIndex((c) => c + 3);

    setRandom(
      texts.map((text, i) => {
        return text.split("").map((t, j) => {
          const index =
            texts
              .filter((_, n) => n < i)
              .reduce((acc, text) => acc + text.length, 0) + j;

          return index < currIndex
            ? t
            : dict[Math.floor(Math.random() * dict.length)];
        });
      }),
    );
  }, [frame]);

  return (
    <>
      {texts.join("").length > currIndex && (
        <rect
          x={0}
          y={0}
          width={`${isMobile ? 20 : 10}`}
          height={`${isMobile ? 28 : 14}`}
          fill="white"
          style={{
            transform: `translate(${p.x * (isMobile ? 12 + 1 : 7) + origin.x}px, ${p.y * (isMobile ? 30 : 20) + origin.y - (isMobile ? 22 : 12)}px)`,
            transition: "transform 0.064s linear",
          }}
        />
      )}

      {random.map((text, i) => {
        return (
          <text
            key={text.join("") + `${i}`}
            x={`${origin.x}`}
            y={`${origin.y + i * (isMobile ? 30 : 20)}`}
            fontSize={isMobile ? "22px" : "12px"}
            fill="white"
          >
            {text.join("").toUpperCase()}
          </text>
        );
      })}
    </>
  );
};
