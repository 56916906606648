import { useState } from "react";

export const PortfolioItem = ({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image: string;
}) => {
  const [hover, setHover] = useState(false);

  const isMobile = window.innerWidth < 500;

  return (
    <div
      style={{
        backdropFilter: "blur(10px)",
        padding: isMobile ? "20px" : "10px 20px",
        borderRadius: "5px",
        boxShadow: "0px 0px 10px #000000",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        width: isMobile ? "80%" : "100%",
        maxWidth: isMobile ? undefined : "800px",
        height: isMobile ? undefined : "200px",
        gap: "15px",
        transform: hover ? "scale(1.1)" : "",
        transition: "transform 0.1s ease",
        cursor: "pointer",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        src={image}
        style={{
          width: "200px",
          height: "fit-content",
          maxHeight: "200px",
          borderRadius: "5px",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <p
          style={{
            color: "white",
            fontSize: 22,
            fontWeight: 600,
            letterSpacing: "1px",
          }}
        >
          {title}
        </p>

        <p
          style={{
            color: "white",
          }}
        >
          {description}
        </p>
      </div>
    </div>
  );
};
