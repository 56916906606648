import React from "react";
import ReactDOM from "react-dom/client";
import { Blog, LandingPage, Portfolio } from "./pages";
import "./index.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Contact } from "./pages/contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "blog",
    element: <Blog />,
  },
  {
    path: "portfolio",
    element: <Portfolio />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
