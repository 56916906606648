import { useState } from "react";
import Logo from "../../assets/logo.png";
import { Button } from "../../components";
import { Background } from "./background";

export function LandingPage() {
  const [hex, setHex] = useState(false);
  const [circle, setCircle] = useState(false);
  const [fear, setFear] = useState(false);

  const isMobile = window.innerWidth < 500;

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "#0d0c1d",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Background hexify={hex} circle={circle} fear={fear} />

      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          alignItems: "center",
          justifyContent: "center",
          gap: isMobile ? "30px" : "50px",
          position: "relative",
          zIndex: "1",
        }}
      >
        <Links setHex={setHex} setCircle={setCircle} setFear={setFear} />

        <img
          src={Logo}
          style={{
            height: isMobile ? "250px" : "500px",
          }}
        />

        <Name />
      </div>
    </div>
  );
}

const Links = ({
  setHex,
  setCircle,
  setFear,
}: {
  setHex: (b: boolean) => void;
  setCircle: (b: boolean) => void;
  setFear: (b: boolean) => void;
}) => {
  const isMobile = window.innerWidth < 500;

  return (
    <div
      style={{
        width: "245px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "start",
          gap: "20px",
          color: "white",
          letterSpacing: "1px",
          width: "100px",
          backdropFilter: "blur(3px)",
          padding: "10px 20px",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px #0000006A",
        }}
      >
        <Button
          to="blog"
          onMouseEnter={() => setHex(true)}
          onMouseLeave={() => setHex(false)}
          title="BLOG"
        />
        <Button
          to="portfolio"
          onMouseEnter={() => setCircle(true)}
          onMouseLeave={() => setCircle(false)}
          title="PORTFOLIO"
        />
        <Button
          to="contact"
          onMouseEnter={() => setFear(true)}
          onMouseLeave={() => setFear(false)}
          title="CONTACT"
        />
      </div>
    </div>
  );
};

const Name = () => {
  const isMobile = window.innerWidth < 500;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        color: "white",
        letterSpacing: "1px",
        textAlign: isMobile ? "center" : "right",
        width: "205px",
        backdropFilter: "blur(3px)",
        padding: "10px 20px",
        borderRadius: "5px",
        boxShadow: "0px 0px 10px #0000006A",
      }}
    >
      <p>CARLO BAGNOLI</p>
      <p>FULLSTACK DEVELOPER</p>
    </div>
  );
};
