import { useEffect, useRef } from "react";
import { Point } from "../../../core";

const NUMBER_OF_POINTS = Math.floor(
  (window.innerHeight * window.innerWidth) / 98 ** 2,
);
const GAP_SIZE = 15;

export const PortfolioBackground = () => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = ref.current;
    const context = canvas?.getContext("2d");
    let animationFrameId: number;
    const w = window.innerWidth;
    const h = window.innerHeight;

    let frame = 0;

    if (context) {
      context.canvas.width = w;
      context.canvas.height = h;
    }

    const render = () => {
      if (!context) {
        return;
      }
      if (frame === GAP_SIZE) {
        setup(context);
      }

      frame++;

      draw(context, frame);

      animationFrameId = window.requestAnimationFrame(render);
    };

    render();

    return () => {
      visited = [];
      linePositions = [...startPoints];
      window.cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={ref}
      style={{
        position: "fixed",
        zIndex: 0,
      }}
    />
  );
};

const w = window.innerWidth;
const h = window.innerHeight;

const startPoints = (() => {
  let points: Point[] = [];
  while (points.length < NUMBER_OF_POINTS) {
    let newPoint = Point.random(w, h).div(GAP_SIZE).floor.mult(GAP_SIZE);
    if (!points.some((p) => p.equal(newPoint))) {
      points.push(newPoint);
    }
  }
  return points;
})();

const endPoints = (() => {
  let points: Point[] = [];
  while (points.length < NUMBER_OF_POINTS) {
    let newPoint = Point.random(w, h).div(GAP_SIZE).floor.mult(GAP_SIZE);
    if (
      !points.some((p) => p.equal(newPoint)) &&
      !startPoints.some((p) => p.equal(newPoint))
    ) {
      points.push(newPoint);
    }
  }
  return points;
})();

let linePositions = [...startPoints];
let visited: Point[] = [];

function setup(ctx: CanvasRenderingContext2D) {
  ctx.strokeStyle = "#999999";
  ctx.fillStyle = "#0d0c1d";
  ctx.lineWidth = 4;

  startPoints.forEach((p) => {
    ctx.beginPath();
    ctx.arc(p.x, p.y, 4, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.fill();
  });

  ctx.fillStyle = "#999999";
  endPoints.forEach((p) => {
    ctx.beginPath();
    ctx.arc(p.x, p.y, 4, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.fill();
  });
}

function draw(ctx: CanvasRenderingContext2D, frame: number) {
  ctx.strokeStyle = "#999999";
  ctx.lineWidth = 3;

  const drawLines = (m: number, i: number, closestPoint: Point) => {
    ctx.beginPath();
    ctx.arc(
      linePositions[i].x + closestPoint.x * m,
      linePositions[i].y + closestPoint.y * m,
      2,
      0,
      2 * Math.PI,
    );
    ctx.fill();
  };

  ctx.fillStyle = "#999999";
  for (let i = 0; i < linePositions.length; i++) {
    if (linePositions[i].equal(endPoints[i])) continue;

    const lines = [
      new Point(-1, 0),
      new Point(0, 1),
      new Point(0, -1),
      new Point(1, 0),
    ].sort((a, b) => {
      const pointing = endPoints[i].sub(linePositions[i]);

      const resA =
        Math.abs(Math.atan2(pointing.y, pointing.x) - Math.atan2(a.y, a.x)) *
        a.abs;
      const resB =
        Math.abs(Math.atan2(pointing.y, pointing.x) - Math.atan2(b.y, b.x)) *
        b.abs;

      return resA - resB;
    });

    const closestPoint = lines
      .filter(
        (l) =>
          !startPoints.some((s) =>
            s.equal(l.mult(GAP_SIZE).add(linePositions[i])),
          ),
      )
      .filter(
        (l) =>
          !visited.some((s) => s.equal(l.mult(GAP_SIZE).add(linePositions[i]))),
      )
      .filter((l) => {
        const newPoint = l.mult(GAP_SIZE).add(linePositions[i]);
        return (
          endPoints[i].equal(newPoint) ||
          !endPoints.some((s) => s.equal(newPoint))
        );
      })[0];

    if (!closestPoint) continue;

    drawLines(frame % GAP_SIZE, i, closestPoint);

    if (frame % GAP_SIZE !== 0) continue;

    linePositions[i] = linePositions[i].add(closestPoint.mult(GAP_SIZE));

    visited.push(linePositions[i]);
  }
}
